import React from 'react'


import Sidebar from './components/SideBar/SideBar'
import MainBot from './components/Main/Main'


const Bot = () => {
  return (
    <>
  
      <MainBot/>
      
    </>
  )
}

export default Bot
